/* html,
body {
  height: 100vh;
  width: 100vw;
} */

@import "colors.css";
@import "animations.css";
@import "font.css";

html {
  background: var(--grey-white);
  overflow: hidden;
}

div {
  user-select: none;
}

.monaco-editor div {
  user-select: auto;
}

div[role="block-grab"] {
  cursor: grab;
}

div[role="block-grabbing"] {
  cursor: grabbing;
}

div[role="button"] {
  cursor: pointer;
}

button {
  cursor: pointer;
}

/* Disable any outline for all inputs */
input,
textarea,
select {
  outline: none;
  padding: 0;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 5px;
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHBvbHlnb24gY2xhc3M9ImNscy0yIiBwb2ludHM9IjEuNDEgNC42NyAyLjQ4IDMuMTggMy41NCA0LjY3IDEuNDEgNC42NyIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIzLjU0IDUuMzMgMi40OCA2LjgyIDEuNDEgNS4zMyAzLjU0IDUuMzMiLz48L3N2Zz4=)
    no-repeat 100% 50%;
  background-size: 1.5em;
  transition: all 0.3s ease-in-out;
  border: 1px solid transparent !important;
  padding-right: 1.5rem;
  padding-left: 0.2rem;
}

select:hover {
  appearance: default;
  -webkit-appearance: default;
  -moz-appearance: default;
  border: 1px solid transparent;
  color: white;
  background:
    url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHBvbHlnb24gY2xhc3M9ImNscy0yIiBwb2ludHM9IjEuNDEgNC42NyAyLjQ4IDMuMTggMy41NCA0LjY3IDEuNDEgNC42NyIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIzLjU0IDUuMzMgMi40OCA2LjgyIDEuNDEgNS4zMyAzLjU0IDUuMzMiLz48L3N2Zz4=)
      no-repeat 100% 50%,
    var(--vintage-code);
  background-size: 1.5em;
}

.splash-container {
  /* Use a media query so that when the viewpoer width is less than 600px, height changes from 100% to fit*/
  max-height: calc(100vh - 65px);
  padding: 2rem;
  grid-col: 1;
  overflow-y: auto;
}

.vertical-splash-separator {
  height: 60%;
  width: 1px;
  background-color: var(--digital-grey-V);
}

.flex-direction-splash-buttons {
  @apply flex-col;
}

@media (max-width: 1120px) {
  .vertical-splash-separator {
    display: none;
  }

  .flex-direction-splash-buttons {
    @apply flex-row;
  }
}

.scrollable-welcome {
  height: 450px;
}

@media (max-width: 895px) {
  .scrollable-welcome {
    height: 100%;
  }
}

.ResizeHandleOuter {
  flex: 0 0 10px;
  position: relative;
  background: var(--whitesmoke);

  /* prevent selection of text */
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.ResizeHandleOuter--vertical {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.ResizeHandleOuter--horizontal {
  border-left: 1px solid black;
  border-right: 1px solid black;
}

.ResizeHandleOuter[data-resize-handle-active] {
  background: var(--light-blue);
  transition: background-color 0.2s linear;
}

.ResizeHandleInner {
  position: absolute;
  transition: background-color 0.2s linear;
  inset: 0 !important;
}

.Icon {
  width: 1em;
  height: 1em;
  position: absolute;
  left: calc(50% - 0.5rem);
  top: calc(50% - 0.5rem);
}

.Icon-horizontal {
  transform: rotate(90deg);
}

.horus-scroll {
  overflow-y: auto;
  border-radius: 0.25rem;
}

.modal-body {
  flex: auto !important;
  position: relative !important;
  padding: 0 !important;
}

.modal-content {
  border-radius: 15px !important;
  overflow: auto !important;
  transition: height 1s ease-in-out !important;
}

.backdrop-blur {
  backdrop-filter: blur(5px) !important;
  -webkit-backdrop-filter: blur(5px);
}

.root {
  height: 100vh;
}

.normal-container {
  background-color: whitesmoke;
  height: 100%;
  border-radius: 15px;
  padding: 0.5rem;
  border: 1px solid var(--vintage-code);
}

.chonky-infoContainer {
  display: none !important ;
}

.sticky-app-header {
  grid-row: 1;
  grid-column: 1;
  position: sticky;
  top: 0;
  height: 65px;
}

.bsc-btn {
  border: none;
  border: 2px solid var(--gradient-blue);
  background: var(--gradient-blue);
  position: relative;
  color: white;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  width: max-content;
  margin: 0 auto;
  padding: 5px;
  border-radius: 20px;
  min-width: 230px;
  text-decoration: none;
  letter-spacing: 2px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  text-align: center;
}

/* .bsc-btn:after {
  background-color: #fff;
  content: "";
  border-radius: 0%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  display: block;
  -webkit-transform: scaleX(0) scaleY(1);
  -ms-transform: scaleX(0) scaleY(1);
  transform: scaleX(0) scaleY(1);
  -webkit-transform-origin: left;
  -ms-transform-origin: left;
  transform-origin: left;
  -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  transition: -webkit-transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  -o-transition: transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  transition: transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  transition: transform 0.6s cubic-bezier(0.19, 1, 0.22, 1),
    -webkit-transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
}

.bsc-btn:hover:after {
  -webkit-transform: scaleX(1) scaleY(1);
  -ms-transform: scaleX(1) scaleY(1);
  transform: scaleX(1) scaleY(1);
}

.bsc-btn:hover {
  color: rgb(0, 0, 0) !important;
}

.bsc-btn:hover svg path {
  fill: #fff;
} */

.bsc-btn:disabled {
  background: #ccc;
  border: 2px solid #ccc;
  color: #fff;
  cursor: not-allowed;
}

.flow-status {
  /* min-width: 105px; */
  @apply flex flex-row gap-2 items-center justify-end;
}

.flow-status > svg {
  margin: 0;
  margin-inline: 0 !important;
}

.blurred-modal-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}

.blurred-modal-content {
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: 5px 5px 100px rgba(0, 0, 0, 0.2);
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(100px) !important;
  -webkit-backdrop-filter: blur(100px);
}

.index-module_lineSelectable__1-mwt {
  user-select: text;
  -webkit-user-select: text;
}

.dv-tabs-container {
  scrollbar-width: auto !important;
}
